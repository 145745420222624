//フォームパーツ

.c-form {
  margin-bottom: 50px;
  // &__list {}
  &__error-box {
      color: $orange;
  }
  &__error-list {
    > .c-list__item {
      &:before {
        color: $orange;
      }
    }
  }
  &__section-inner {
    // l-section__innerの中にあるsection用
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__complete{ //送信完了画面
    text-align: center;
    @include mq-max(sm) {
      text-align: inherit;
    }
  }
  &__item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      margin-bottom: 10px;
    }
  }
  &__wrap {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    label { //checkbox radio調整用CSS
      display: inline-block;
      width: 100%;
    }
    & > img { //画像表示時の処理
      width: 100%;
      height: auto;
      max-width: 300px;
    }
  }
  &__label {
    background-color: $lightRed;
    display: inline-block;
    color: $white;
    font-weight: 500;
    font-size: 13px;
    padding: 2px 10.5px;
    margin-left: 8px;
    flex: 0 0 48.2px;
    white-space: nowrap;
    // 必須項目ではないとき、ラベルを表示させない
    &:empty {
      display: none;
    }
  }
  &__txt {
    font-size: 14px;
    margin-bottom: 10px;
    color: #555555;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__btn {
    text-align: center;
    .c-btn {
      &:focus {
        outline: none;
      }
    }
  }


  // ================================================
  //select用CSS
  &__select-wrap {
    position: relative;
    overflow: hidden;
    text-align: center;
    border: 1px solid $line;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    &:focus {
      outline: none;
    }
    &:before { //右側の▼
      position: absolute;
      top: 1em;
      right: 0.9em;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $keyColor;
      pointer-events: none;
    }

    select {
      width: 100%;
      cursor: pointer;
      text-overflow: ellipsis;
      outline: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 11px 15px;
    }
  }
  // ================================================
  //ラジオボタン、チェックボタン
  .c-form__check-parts,
  .c-form__radio-parts{
    //.c-form__radio label, .c-form__check label と同じ
    display: block;
    font-size: 16px;
    position: relative;
    padding-left: 1em;
    margin-bottom: 15px;
    @include mq-max(sm) {
      display: block;
      padding: 12px 16px;
      padding-left: calc(1em + 16px);
      border: 1px solid $line;
      border-radius: 5px;
    }
  }
  // ================================================
  // radioボタン(easy mail form対応)
  input[type="radio"] {
    display: none;
  }
  .c-form__radio-parts::before{
    // .c-form__radio label:before と同じ
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    top: .45em;
    left: 0;
    border: 1px solid $line;
    @include mq-max(sm) {
      top: 53%;
      left: 16px;
      transform: translateY(-50%);
    }
  }
  input[type="radio"]:checked {
    & + .c-form__radio-parts{
      &:before {
        background-color: $keyColor;
        border-color: $keyColor;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $white;
        position: absolute;
        top: 0.72em;
        left: .3em;
        @include mq-max(md) {
          top: 0.7em;
          left: .25em;
        }
        @include mq-max(sm) {
          top: 53%;
          left: 1.25em;
          transform: translateY(-50%);
        }
        //IE対応
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none){
          left: 0.25em;
        }
        //safari、Macのchrome対応
        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-color-index:0) {
          left: 0.25em;
          @include mq-max(sm) {
            left: 1.25em;
          }
        }
      }
    }
  }
  // ================================================
  // checkボタン(easy mail form対応用)
  input[type="checkbox"] {
    display: none;
  }
  .c-form__check-parts::before{
    // .c-form__check label:before と同じ
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: $white;
    border-radius: 3px;
    position: absolute;
    top: .45em;
    left: 0;
    border: 1px solid $line;
    @include mq-max(sm) {
      top: 51%;
      left: 16px;
      transform: translateY(-50%);
    }
  }
  input[type="checkbox"]:checked + .c-form__check-parts::before {
    background-color: $keyColor;
    border-color: $keyColor;
  }
  input[type="checkbox"]:checked + .c-form__check-parts::after{
    @include fa('f00c');
    color: $white;
    font-size: 8px;
    position: absolute;
    top: 1em;
    left: 1.7px;
    line-height: 1;
    @include mq-max(md) {
      // left: .2em;
      left: 2px;
    }
    @include mq-max(sm) {
      top: 51%;
      // left: 2.1em;
      left: 17px;
      transform: translateY(-50%);
    }
    //IE対応
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none){
      top: 44%;
      font-size: 8px;
    }
    @-moz-document url-prefix() {
      top: 1.3em;
      left: 2.2px;
    }
  }
  // ================================================

  //input
  input {
    &:focus {
      outline: none;
    }

    //text
    &[type="text"] {
      border: 1px solid $line;
      border-radius: 5px;
      padding: 11px 15px;
      width: 100%;
      font-size: 16px;
    }
  }
  textarea {
    border: 1px solid $line;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    padding: 11px 15px;
    &:focus {
      outline: none;
    }
  }
}


//safariバグ対応
_::-webkit-full-page-media, _:future, :root .c-form__check input:checked + label:after {
  top: 40%;
  @include mq-max(sm) {
    top: 52%;
    left: 2.3em;
  }
}

//Edge対応
_:lang(x)::-ms-, .c-form__check input:checked + label:after {
  top: 1.2em;
  @include mq-max(md) {
    left: 1px;
  }
  @include mq-max(sm) {
    top: 52%;
    left: 17px;
  }
}