///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  padding: 4.5px 29.5px;
  color: $white;
  background-color: $keyColor;
  line-height: 1.25;
  letter-spacing: 0.08em;
  border: 2px solid $keyColor;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: all $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &>span {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    &:after {
      @include fa('f054');
      color: $white;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      transition: right $transition;
    }
  }

  //ホバー
  &:hover {
    color: $white;
    background-color: $accentColor;
    border-color: $accentColor;
    border-radius: 36px;
    &>span {
      &:after {
        right: -5px;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      content: none;
    }
    &>span {
      &:after {
        @include fa('f35d');
      }
    }
  }

  //PDF
  &[href$=".pdf"] {
    &:after {
      content: none;
    }
    &>span {
      &:after {
        @include fa('f1c1');
      }
    }
  }

  /////////////////////////////////////////
  //ボタンバリエーション
  /////////////////////////////////////////
  //膨らむタイプ
  &--expansion {
    &:hover {
      padding: 14.5px 34.5px;
      transform: translate(5px,10px);
      @include mq-max(md) {
        padding: 4.5px 29.5px;
        transform: none;
      }
    }
  }

  //白
  &--white {
    background-color: $white;
    color: $keyColor;
    border-color: $white;
    &>span {
      &:after {
        color: $keyColor;
      }
    }
    &:hover {
      background-color: $white;
      color: $accentColor;
      border-color: $white;
      &>span {
        &:after {
          color: $accentColor;
        }
      }
    }
  }

  //大きいボタン
  &--large {
    padding: 14.5px 20px;
    max-width: 260px;
    width: 100%;
  }

  // もっと大きいボタン
  &--ll {
    max-width: 320px;
  }
}


//サブボタン
.c-subBtn {
  font-size: 14px;
  font-weight: 700;
  color: $keyColor;
  padding-right: 18px;
  position: relative;
  transition: all $transition;
  &:after {
    @include fa('f105');
    font-size: 20px;
    line-height: 1;
    position: absolute;
    top: 55%;
    right: 0px;
    transform: translateY(-50%);
    transition: all $transition;
  }
  &:hover {
    color: $accentColor;
    &:after {
      right: -5px;
    }
  }

  //PDF,別タブ調整
  &[target="_blank"], &[href$=".pdf"]  {
    &:after {
      font-size: 16px;
    }
  }
}

//ページ内リンク
.c-anchorLink {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -20px;
  @include mq-max(sm) {
    margin-bottom: -20px;
  }
  @include mq-max(xs) {
    margin: 0 -5px -20px;
  }
  &__item {
    padding: 0 15px 20px;
    @include mq-max(sm) {
      width: 33%;
      padding-bottom: 25px;
    }
    @include mq-max(xs) {
      width: 50%;
      padding: 0 5px 20px;
    }
  }
  &__btn {
    font-size: 14px;
    font-weight: 700;
    color: $keyColor;
    display: inline-block;
    padding-right: 18px;
    position: relative;
    transition: all $transition;
    &:after {
      @include fa('f107');
      font-size: 20px;
      line-height: 1;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      transition: all $transition;
    }
    &:hover {
      color: $accentColor;
      &:after {
        right: -5px;
      }
    }
  }

  //2カラムパターン
  &--column2 {
    .c-anchorLink {
      &__item {
        width: 50%;
      }
    }
  }
  //2カラムパターン(xs以下1カラム)
  &--column2-xs-1 {
    .c-anchorLink {
      &__item {
        width: 50%;
        @include mq-max(xs) {
          width: 100%;
        }
      }
    }
  }
}

//アーカイブ用前後＋一覧ボタン
.c-archiveBtn {
  display: flex;
  margin: 40px -5px -10px;
  justify-content: center;
  &__btn {
    margin: 0;
    max-width: 260px;
    width: 100%;
    padding: 0 5px 10px;
    &>a {
      @extend .c-btn;
      @extend .c-btn--large;
      max-width: 100%;
      @include mq-max(xs) {
        padding: 15px 5px;
      }
      &:before,&:after {
        transition: all $transition;
      }
    }
    &--prev {
      a {
        &:before {
          @include fa('f053');
          margin-right: 7px;
        }
        &:hover {
          &:before {
            margin-right: 12px;
          }
        }
      }
    }
    &--next {
      a {
        &:after {
          @include fa('f054');
          margin-left: 7px;
        }
        &:hover {
          &:after {
            margin-left: 12px;
          }
        }
      }
    }
    // &--all {
    //   a {}
    // }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-btnArea{
  max-width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 20px auto;
  }
  @include mq-max(sm) {
    width: $full-size;
    margin: 0 auto;
    padding: 10px 0;
  }
}

.c-btn {
  &.c-btnForm {
    display: inline-block;
    width: auto;
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }
    &:focus {
      outline: none;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      line-height: 28px;
      min-width: inherit;
      background: $white;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm){
        font-size: 96%;
        min-width: inherit;
        width: 50%;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 20px;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        @include fa('f053');
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        text-align: left;
        @include mq-max(xs) {
          left: 8px;
        }
      }
      &:after {
        content: none;
      }
    }
  }
}
