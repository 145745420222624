//サブメニュー
.c-subMenu {
  &__wrap {
    background-color: $lightGray;
    padding: 25px 20px 20px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    &>a {
      color: $keyColor;
      font-weight: 700;
      display: block;
      padding: 9px 17px;
      background-color: $white;
      transition: all $transition;
      border: 3px solid transparent;
      &:after {
        display: none;
      }
      &:hover {
        background-color: $lightBlue2;
        border-color: $accentColor;
        &>span {
          &:after {
            color: $accentColor;
            right: -5px;
          }
        }
      }
      &>span {
        position: relative;
        display: inline-block;
        padding-right: 15px;
        &:after {
          @include fa('f105');
          color: $keyColor;
          line-height: 1;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: all $transition;
        }
      }
    }

    //現在地
    &.current {
      &>a {
        background-color: $lightYellow;
        color: $textColorLight;
        position: relative;
        &:before {
          content: '';
          width: 3px;
          height: calc(100% + 3px * 2);
          display: block;
          background-color: $textColorLight;
          position: absolute;
          top: -3px;
          left: -3px;
        }
        &>span {
          &:after {
            color: $textColorLight;
          }
        }
        &:hover {
          border-color: transparent;
        }
      }
    }

    //ドロップダウン
    &--parent {
      &.current {
        &>a {
          color: $keyColor;
          &:before {
            content: none;
          }
          &>span {
            &:after {
              top: 60%;
              transform: translateY(-50%) rotate(90deg);
              color: $keyColor;
            }
          }
          &:hover {
            &>span {
              &:after {
                right: 0;
              }
            }
          }
        }
      }
      &>a {
        background-color: $white !important;
      }
      &:not(.current) {
        .c-subMenu--child {
          display: none;
        }
      }
    }
  }


  //ドロップダウン
  &--child {
    padding-left: 30px;
  }

}