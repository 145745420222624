//フロー
.c-flow {
  counter-reset: step;
  &__item {
    margin-bottom: 40px;
    position: relative;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 23px 0 23px;
      border-color: $line transparent transparent transparent;
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  &__heading {
    &:before {
      content: 'STEP 'counter(step);
      counter-increment: step;
      display: block;
      font-size: 14px;
      font-weight: 700;
      font-family: $Lato;
      color: $keyColor;
      margin-bottom: 5px;
    }
  }
}