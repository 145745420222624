.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: calc(28 / 16);
  &:last-child {
    margin-bottom: 0;
  }

  //マーカー
  &--marker {
    padding-left: 1.8em;
    &>.c-list__item {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 2px;
        background-color: $keyColor;
        position: absolute;
        top: .9em;
        left: -28px;
      }
    }
  }

  // 丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: -0.45em;
        left: -0.8em;
        font-size: 2.8em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.52em;
        }
      }
    }
  }

  //数字
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count)'.';
        font-weight: 700;
        position: absolute;
        top: 0;
        left: -1.1em;
        color: $keyColor;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
      &:nth-child(n + 10) {
        &:before {
          left: -1.6em;
        }
      }
    }
    //数字かっこ
    &-brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          font-weight: 700;
          position: absolute;
          top: 0;
          left: -1.5em;
          color: $keyColor;
          @media all and (-ms-high-contrast: none) {
            top: 0.1em;
          }
        }
      }
    }
  }

  //コメント
  &--comment {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.29em;
        }
      }
    }
    // 番号付き注釈
    &-num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &:before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          top: 0;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0.1em;
          }
        }
      }
    }
  }
}