//沿革

.c-timeline {
  &--month {
    .c-timeline__list {
      &:before {
        left: 15px;
        @include mq-max(xs) {
          left: 12px;
        }
      }
    }
  }
  &__list {
    position: relative;
    &:before {
      content: '';
      width: 2px;
      height: 100%;
      background-color: $keyColor;
      position: absolute;
      top: 0;
      left: 28px;
      @include mq-max(xs) {
        left: 20px;
      }
    }
  }
  &__item {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
      .c-timeline__title {
        background-color: $white;
      }
    }
  }
  &__title {
    // flex: 0 0 150px;
    flex: 0 0 100px;
    font-size: 20px;
    color: $keyColor;
    margin-bottom: 0;
    position: relative;
    @include mq-max(xs) {
      font-size: 16px;
      flex: 0 0 80px;
    }
    &>span {
      display: inline-block;
      background-color: $white;
      position: relative;
      z-index: 2;
      padding: 5px 0;
    }
  }
  &__desc {
    flex: 0 0 calc(100% - 150px);
    padding-top: 5px;
    width: 100%;
    @include mq-max(xs) {
      flex: 0 0 calc(100% - 80px);
    }
    &--full {
      position: relative;
      z-index: 1;
      background-color: $white;
      padding-bottom: 30px;
      flex: 0 0 100%;
    }
  }
}