//通常ボックス
.c-box {
  background: $white;
  border: 1px solid $line;
  padding: 40px;
  margin-bottom: 30px;
  @include mq-max(sm) {
    padding: 20px;
  }
  &:last-child {
    margin-bottom: 0
  }
  &__heading {
    font-size: 20px;
    margin-bottom: 15px;
    @include mq-max(sm) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    //見出し小文字付き
    &--qa {
      font-size: 20px !important;
      &>small {
        display: block;
        font-size: 16px;
        color: $textColor;
        margin-bottom: 10px;
      }
    }
  }

  //派生
  &--yellow {
    background-color: $lightYellow;
    border-color: $lightYellow;
  }
  &--gray {
    background-color: $lightGray;
    border-color: $lightGray;
  }
}


//お問い合わせボックス
.c-contactBox {
  background-color: $lightBlue2;
  padding: 40px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    padding: 20px;
  }
  &__inner {
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      flex-direction: column;
    }
  }
  &__heading {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    @include mq-max(sm) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &__tel {
    width: 50%;
    border-right: 1px solid $accentColor;
    @include mq-max(sm) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $accentColor;
      padding-bottom: 15px;
    }
  }
  &__mail {
    width: 50%;
    @include mq-max(sm) {
      width: 100%;
      padding-top: 15px;
    }
  }
  &__num {
    color: $textColor;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      width: 50px;
      height: 33px;
      display: inline-block;
      background: url('/inc/image/common/ico/ico_freedial-black.png')no-repeat center / contain;
      margin-right: 8px;
    }
    &--phone {
      &:before {
        @include fa('f095');
        width: auto;
        height: auto;
        background: none;
      }
    }
    &--fax {
      >p {
        font-size: 26px;
        font-weight: 700;
      }
      &:before {
        @include fa('f1ac');
        width: auto;
        height: auto;
        background: none;
      }
    }
    &--none {
      &:before {
        content: none;
      }
    }
  }
  &__term {
    text-align: center;
    line-height: 2;
    @include mq-max(sm) {
      font-size: 15px;
      line-height: 1.75;
    }
  }
  &__btn {
    text-align: center;
  }

  //1カラムVer
  &--single {
    .c-contactBox__inner {
      align-items: flex-start;
    }
    .c-contactBox__tel {
      border: none;
      width: 100%;
      @include mq-max(sm) {
        padding-bottom: 0;
      }
    }
    .c-contactBox__heading {
      text-align: left;
    }
    .c-contactBox__num {
      justify-content: flex-start;
    }
    .c-contactBox__term {
      text-align: left;
    }
  }

  //ボタンのみ用
  &--btns {
    @include mq-max(sm) {
      padding: 30px 20px;
    }
    .c-contactBox__inner {
      justify-content: center;
    }
    .c-contactBox__btn {
      width: 50%;
      @include mq-max(sm) {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}