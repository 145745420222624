.l-f {
  background-color: $keyColor;
  @include mq-max(sm) {
    padding-bottom: 45px;
  }
  &__inner {
    width: 100%;
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 28px 0 25px;
    @include mq-max(content) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @include mq-max(md) {
      padding: 0 0 25px;
    }
  }

  &__list {
    display: flex;
    margin-bottom: 35px;
    @include mq-max(md) {
      flex-wrap: wrap;
    }
    @include mq-max(xs) {
      margin-bottom: 40px;
    }
  }
  &__item {
    margin-right: 30px;
    @include mq-max(md) {
      width: 50%;
      margin: 0;
      border-bottom: 1px solid $line;
      &:nth-child(odd) {
        border-right: 1px solid $line;
      }
    }
    @include mq-max(xs) {
      width: 100%;
      border-right: none !important;
    }
    &:last-child {
      margin-right: 0;
    }
    &>a {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      position: relative;
      display: block;
      padding-right: 20px;
      @include mq-max(md) {
        padding: 15.5px 20px;
      }
      &:before {
        content: '';
        width: 0%;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-color: $white;
        transition: all $transition;
        @include mq-max(md) {
          display: none;
        }
      }
      &:after {
        transition: all $transition;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        line-height: 1;
        @include mq-max(md) {
          position: absolute;
          right: 25px;
        }
      }
      &:not([target="_blank"]):not([href$=".pdf"]) {
        &:after {
          @include fa('f054');
          display: inline-block;
        }
      }
      //ホバーアクション
      &:hover {
        color: $white;
        &:before {
          width: calc(100% + 5px);
        }
        &:after {
          right: -5px !important;
          transform: translateY(-50%) translate3d(0, 0, 0);
          @include mq-max(md) {
            right: 25px !important;
          }
        }
      }
    }
  }

  //お問い合わせ
  &__contact {
    @include mq-max(content) {
      padding: 0 15px;
    }
    @include mq-max(md) {
      margin-bottom: 30px;
    }
    &-title {
      font-size: 16px;
      font-weight: 700;
      color: $white;
      margin-bottom: 12px;
      @include mq-max(md) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    &-tel {
      color: $white;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      @include mq-max(md) {
        flex-direction: column;
      }
    }
    &-num {
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0.08em;
      line-height: 1;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        width: 50px;
        height: 33px;
        display: inline-block;
        background: url('/inc/image/common/ico/ico_freedial-white.png')no-repeat center / contain;
        margin-right: 20px;
        @include mq-max(md) {
          display: none;
        }
      }
      &>[data-js="tel"] {
        a {
          //スマホ時電話番号ボタン
          @include mq-max(md) {
            font-size: 22px;
            font-weight: 600;
            background-color: $white;
            color: $keyColor;
            padding: 15px 60px 15px 109px;;
            display: block;
            border-radius: 4px;
            position: relative;
            margin-bottom: 12px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 60px;
              transform: translateY(-50%);
              width: 39px;
              height: 25.5px;
              background: url('/inc/image/common/ico/ico_freedial-blue.png')no-repeat center / contain;
            }
          }
        }
      }
    }
    &-term {
      font-size: 16px;
      font-weight: 400;
      margin-left: 30px;
      @include mq-max(xs) {
        font-size: 15px;
        text-align: center;
        margin: 0;
      }
    }
  }

  &__btns {
    display: flex;
    margin-bottom: 30px;
    @include mq-max(md) {
      justify-content: center;
    }
    @include mq-max(xs) {
      flex-direction: column;
      align-items: center;
    }
    &>li {
      margin-right: 15px;
      @include mq-max(xs) {
        margin: 0 0 30px;
      }
      &:last-child {
        margin-right: 0;
        @include mq-max(xs) {
          margin: 0;
        }
      }
    }
    .c-btn {
      @include mq-max(xs) {
        width: 250px;
      }
    }
  }

  //SNS
  &__sns {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include mq-max(md) {
      justify-content: center;
    }
    @include mq-max(xs) {
      margin-bottom: 40px;
    }
    &>li {
      margin-right: 10px;
      @include mq-max(md) {
        margin-right: 50px;
      }
      @include mq-max(xs) {
        margin-right: 30px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &-ico {
      width: 34px;
      height: 34px;
      display: block;
      position: relative;
      &:after {
        content: '' !important;
        margin: 0 !important;
      }
      &:hover {
        &:after {
          width: 100%;
          height: 100%;
          @include mq-max(md) {
            width: 0%;
            height: 0%;
          }
        }
      }
      &:after {
        content: '';
        width: 0%;
        height: 0%;
        border-radius: 50%;
        background-color: $accentColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all $transition;
      }
      &:before {
        content: '';
        // font-family: 'Font Awesome 5 Brands';
        // font-size: 18px;
        // font-weight: 600;
        display: inline-block;
        // color: $white;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
      }
      &.line {
        &:before {
          // content: "\f3c0";
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_line.svg')no-repeat center / contain;
        }
      }
      &.facebook {
        &:before {
          // content: "\f39e";
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_facebook.svg')no-repeat center / contain;
        }
      }
      &.twitter {
        &:before {
          // content: "\f099";
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_twitter.svg')no-repeat center / contain;
        }
      }
      &.instagram {
        &:before {
          // content: "\f16d";
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_insta.svg')no-repeat center / contain;
        }
      }
      &.youtube {
        &:before {
          // content: "\f167";
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_youtube.svg')no-repeat center / contain;
        }
      }
    }
  }

  //Copyright
  &__copyright {
    display: block;
    color: $white;
    font-size: 13px;
    font-weight: 400;
    @include mq-max(content) {
      padding: 0 15px;
    }
    @include mq-max(md) {
      text-align: center;
    }
  }


  //新規向けTOP
  &--new {
    background-color: $keyColor-new;
    .l-f {
      &__item {
        @include mq-max(content) {
          margin-right: 10px;
        }
        @include mq-max(md) {
          margin-right: 0;
        }
        &>a {
          &:before {
            background-color: $white;
          }
        }
      }
      &__sns {
        &-ico {
          &:after {
            background-color: $accentColor-new;
          }
        }
      }
    }
    .pageTop {
      background-color: rgba($keyColor-new,.3);
      @include mq-max(md) {
        background-color: $accentColor-new;
      }
      &:hover {
        background-color: rgba($accentColor-new,.3);
        opacity: .3;
        @include mq-max(md) {
          background-color: $accentColor-new;
        }
      }
    }
  }

}
