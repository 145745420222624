//サイドボタン(共通)
.c-sideBtn {
  position: fixed;
  top: 40%;
  right: 0;
  transform: translateY(-50%);
  width: 68px;
  z-index: 5;
  @include mq-max(sm) {
    top: auto;
    bottom: 0;
    right: 0;
    transform: none;
    width: 100%;
  }
  &__list {
    @include mq-max(sm) {
      display: flex;
    }
  }
  &__item {
    position: relative;
    right: -11px;
    transition: all $transition;
    border-bottom: 1px solid $white;
    &:last-child {
      border-bottom: none;
    }
    @include mq-max(sm) {
      border-bottom: none;
      border-right: 1px solid $white;
      &:last-child {
        border-right: none;
      }
    }
    &:hover {
      right: 0;
    }
    @include mq-max(sm) {
      position: relative;
      right: 0;
      width: 50%;
    }
    //人気商品プレゼント
    &--present {
      display: none;
      @include mq-max(sm) {
        display: block;
        border-right: 1px solid $white;
      }
      .c-sideBtn__link {
        &>span {
          @include mq-max(sm) {
            padding-left: 20px;
            &:before {
              content: '';
              width: 24px;
              height: 24px;
              display: block;
              background: url('/inc/image/common/ico/ico_present.png')no-repeat center / contain;
              position: absolute;
              top: -4px;
              left: -7px;
            }
          }
        }
      }
    }
    //ネット注文
    &--net {
      @include mq-max(sm) {
        width: calc(110 / 375 * 100%);
      }
      .c-sideBtn__link {
        border-radius: 6px 0 0 0;
        @include mq-max(sm) {
          border-radius: 0;
        }
        br {
          @include mq-max(sm) {
            display: none;
          }
        }
        &>span {
          @include mq-max(sm) {
            left: 12px;
          }
          &:before {
            content: '';
            background: url('/inc/image/common/ico/ico_pc.png')no-repeat center / contain;
            width: 23px;
            height: 19.5px;
            display: block;
            position: absolute;
            top: -23px;
            left: 11px;
            @include mq-max(sm) {
              top: 0px;
              left: -28px;
            }
          }
        }
      }
    }
    //資料請求
    &--doc {
      .c-sideBtn__link {
        border-radius: 0 0 0 6px;
        @include mq-max(sm) {
          border-radius: 0;
        }
        br {
          @include mq-max(sm) {
            display: none;
          }
        }
        &>span {
          &:before {
            content: '';
            background: url('/inc/image/common/ico/ico_pamph.png')no-repeat center / contain;
            width: 18px;
            height: 24px;
            display: block;
            position: absolute;
            top: -24px;
            left: 5px;
            @include mq-max(sm) {
              top: -2px;
              left: -25px;
            }
          }
        }
      }
    }
  }
  &__link {
    border-radius: 6px 0 0 6px;
    color: $white !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.08em;
    display: block;
    padding: 34px 14.4px 10px 3.4px;
    text-align: center;
    position: relative;
    background-color: $keyColor-new;
    @include mq-max(sm) {
      border-radius: 0;
      font-size: 12px;
      padding: 13.5px 10px;
      text-align: center;
    }
    &:after {
      content: none !important;
    }
    span {
      position: relative;
      @include mq-max(sm) {
        display: inline-block;
      }
    }
    &:hover {
      border-radius: 6px 0 0 6px !important;
      @include mq-max(sm) {
        border-radius: 0 !important;
      }
    }
  }


  //新規向け
  &--new {
    .c-sideBtn {
      &__item {
        &--present {
          @include mq-max(sm) {
            width: 30%;
          }
          &>a {
            @include mq-max(xs) {
              padding: 4.5px 10px;
            }
            &>span {
              &:before {
                @include mq-max(xs) {
                  top: 5px;
                }
              }
            }
          }
        }
        &--net {
          @include mq-max(sm) {
            width: 30%;
          }
        }
        &--doc {
          @include mq-max(sm) {
            width: 40%;
          }
          &>a {
            &>span {
              @include mq-max(sm) {
                left: -10px;
              }
            }
          }
        }
      }
    }
  }


  // 組合員向け
  &--member {
    .c-sideBtn {
      &__link {
        text-align: center;
        background-color: $keyColor;
      }

      &__item {
        //
        //店舗チラシ
        &--doc {
          @include mq-max(sm) {
            width: calc(155 / 375 * 100%);
          }
          .c-sideBtn__link {
            border-radius: 0 0 0 6px;
            @include mq-max(sm) {
              border-radius: 0;
            }
            &>span {
              @include mq-max(sm) {
                left: -10px;
              }
            }
          }
        }

        //初めての方
        &--first {
          display: none;
          @include mq-max(sm) {
            display: block;
            width: calc(110 / 375 * 100%);
          }
          .c-sideBtn__link {
            background-color: $conversion;
            &>span {
              @include mq-max(sm) {
                left: 10px;
              }
              &:before {
                @include mq-max(sm) {
                  content: '';
                  background: url('/inc/image/common/ico/ico_kentou.png')no-repeat center / contain;
                  width: 18px;
                  height: 16.5px;
                  display: block;
                  position: absolute;
                  top: 2px;
                  left: -23px;
                }
              }
            }
          }
        }
      }
    }
  }


}