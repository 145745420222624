///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //h2見出し
  &-2 {
    font-size: 32px;
    font-weight: 700;
    color: $keyColor;
    margin-bottom: 24px;
    @include mq-max(sm) {
      font-size: 25px;
      margin-bottom: 20px;
    }
    //下線あり
    &--underline {
      border-bottom: 3px solid $accentColor;
      padding-bottom: 12px;
    }

    &>small {
      font-size: 16px;
      font-weight: 700;
      display: block;
      padding-bottom: 10px;
      position: relative;
      margin-bottom: 4px;
      &:after {
        content: '';
        width: 38px;
        height: 2px;
        background-color: $accentColor;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 8px;
      }
    }

    //真ん中寄席と一緒に使う
    &--shortbar {
      position: relative;
      &:before {
        content: '';
        width: 38px;
        height: 2px;
        background-color: $accentColor;
        display: inline-block;
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    //h2時small付きの真ん中寄せ
    &.c-ttl--center {
      &>small {
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }


  //h3見出し
  &-3 {
    font-size: 22px;
    font-weight: 700;
    color: $keyColor;
    margin-bottom: 15px;
    position: relative;
    @include mq-max(sm) {
      font-size: 20px;
      margin-bottom: 10px;
    }
    &--line {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted $accentColor;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1;
      }
      &>span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 7px;
      }
    }
  }


  // 共通設定
  &--center {
    text-align: center;
  }
  &--mbLarge {
    margin-bottom: 1.3em;
  }
  &--black {
    color: $textColor !important;
  }

}


//新規向けTOP用
.c-topH2 {
  font-size: 32px;
  font-weight: 700;
  color: $keyColor-new;
  margin: 0 0 30px;
  @include mq-max(sm) {
    font-size: 27px;
  }
  &:before,&:after {
    content: '';
    display: block;
  }
  &:before {
    width: 104px;
    height: 39px;
    background: url('/inc/image/common/ico/ico_shine.png')no-repeat center / contain;
    margin: 0 auto 6px;
  }
  &:after {
    width: 209.5px;
    height: 13.5px;
    background: url('/inc/image/common/ico/ico_heading-bottom.png')no-repeat center / contain;
    margin: 10px auto 0;
  }
  &--center {
    text-align: center;
  }
}