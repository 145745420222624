//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0 0 50px;
  @include mq-max(content) {
    padding-top: 50px;
    padding-bottom: 0;
  }
  @include mq-max(sm) {
    width: $full-size;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 50px 0;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 0 $contentPadding 50px;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 50px;//お好みで変更
  }
  &__inner {
    padding: 0 0 50px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }

  &--lower {
    max-width: $contentWidth-lower;
  }

  &--full {
    max-width: 100%;
  }

  &--medium {
    max-width: 80%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 50%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  [class*="grid"] {
    margin-bottom: -1 * $contentPadding;
  }
}

//background full width
@mixin bg_scss() {
  @include mq-max(content) {
    margin-bottom: 50px;;
  }
  &>.l-section {
    @include mq-max(content) {
      padding-top: 50px;
    }
  }
}

.l-section--keyColor {
  background-color: $keyColor;
  @include bg_scss();
}
.l-section--lightGray {
  background-color: $lightGray;
  @include bg_scss();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  &--xsL {
    @include mq-max(xs) {
      text-align: left !important;
    }
  }
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-under-sm {
  display: none;
  @include mq-max(sm) {
    display: block;
  }
}

.u-not-xs {
  @include mq-max(xs) {
    display: none;
  }
}

.u-not-under-sm {
  @include mq-max(sm) {
    display: none;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//overflow:hidden
.u-of--hidden {
  overflow: hidden;
}

//互い違い
.l-alternate {
  padding: 50px 0;
  // &__inner {}
  &__block {
    display: flex;
    margin-bottom: 130px;
    position: relative;
    @include mq-max(sm) {
      flex-direction: column;
      margin-bottom: 80px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      display: block;
      width: 25%;
      height: 492px;
      background-color: $lightBlue1;
      border-radius: 0 12px 12px 0;
      position: absolute;
      top: -50px;
      left: 0;
      z-index: -1;
      @include mq-max(sm) {
        width: (147 / 375 * 100%);
        height: 351px;
        top: -30px;
      }
    }
    //逆Ver
    &--reverse {
      flex-direction: row-reverse;
      @include mq-max(sm) {
        flex-direction: column;
      }
      &:before {
        left: auto;
        right: 0;
        border-radius: 12px 0 0 12px;
      }
      .l-alternate__pic {
        margin-right: 0;
        margin-left: 30px;
        border-radius: 12px 0 0 12px;
        @include mq-max(sm) {
          margin: 0 0 0 auto;
        }
      }
      .l-alternate__desc {
        padding-right: 0;
        padding-left: $contentPadding / 2;
        @include mq-max(sm) {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }
  &__pic {
    flex: 0 0 50%;
    width: 50%;
    height: 392px;
    margin-right: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 12px 12px 0;
    @include mq-max(sm) {
      // flex: 0 0 (298 / 375 * 100%);
      flex: auto;
      width: (298 / 375 * 100%);
      height: 171px;
      margin: 0;
    }
    &--topCenter {
      background-position: center top;
    }
  }
  &__desc {
    padding-top: 30px;
    width: calc((100% / 2) - ((100vw - #{$contentWidth}) / 2));
    padding-right: $contentPadding / 2;
    @include mq-max(sm) {
      margin-left: auto;
      width: 90%;
    }
  }
}


//ボックスレイアウト(.c-boxと併用)
.l-2columnBox {
  .c-box {
    padding: 20px;
    &__heading {
      font-size: 16px;
      color: $keyColor;
      margin-bottom: 8px;
    }
  }

  //パネルりんく
  &--link {
    .c-box {
      padding-bottom: 70px;
      position: relative;
      border-radius: 6px;
      box-shadow: $box-shadow;
    }
    .c-btn {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    //パネルりんく(中にボタンを配置していないver)
    &-noBtn {
      .c-box {
        padding-bottom: 20px;
        position: relative;
        border-radius: 6px;
        box-shadow: $box-shadow;
      }
    }
  }

}


//投稿系ページ用
.l-entry-body {
  overflow: hidden;
  a {
    &[target="_blank"],
    &[href$=".pdf"] {
      &:after {
        content: none;
      }
    }
  }
  img {
    max-width: 100%;
    @include mq-max(md) {
      width: 100%;
      height: auto;
    }
  }
}