.l-header {
  background-color: $white;
  border-bottom: 1px solid $line-menu;
  @include mq-max(md) {
    background-color: transparent;
    border: none;
  }
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $contentWidth;
    margin: 0 auto;
    // padding: 0 30px;
    position: relative;
    z-index: 100;
    height: $headerHeight;
    @include mq-max(content) {
      padding: 0 15px;
    }
    @include mq-max(md) {
      display: block;
      padding: 0;
      // position: fixed;
      // top: 0;
      background: transparent;
      height: auto;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    width: 286px; // 状況によって変更可
    @include mq-max(md) {
      width: 247.8px;
      margin: 0;
      line-height: 0;
    }
    img {
      max-width: 100%;
      // width: 100%;
      height: auto;
    }
  }
  .logo {
    margin-top: 20px;
    @include mq-max(md) {
      margin-top: 0;
    }
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 1;
    background-color: $white;
    @include mq-max(md) {
      padding: 18.1px 10px;
      line-height: 0;
    }
  }

  //ハンバーガーメニュー時の「ご検討中の方はこちら」
  &__kentouBtn {
    @extend .l-headNav__item;
    @extend .l-headNav__item--kentou;
    text-align: center;
    margin-bottom: 20px;
    display: none;
    @include mq-max(md) {
      display: block;
    }
    &>a {
      padding: 9px 24px 9px 50px !important;
      &:before {
        top: 8px !important;
      }
    }
  }


  //新規向けTOP
  &--new {
    h1,.logo {
      width: 176.5px;
    }
    .l-header {
      &__modules {
        @include mq-max(md) {
          padding: 16.1px 10px;
        }
      }
    }
  }

}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max(md) {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span{
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $black;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1){
        top: 9px;
      }
      &:nth-child(2){
        top: 18px;
      }
      &:nth-child(3){
        top: 27px;
      }
    }
    &.is-active{
      span{
        &:nth-child(1){
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3){
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
