///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }

  dl {
    width: 100%;
    display: flex;
    align-items: flex-start;
    @include mq-max(sm) {
      flex-direction: column;
    }
    dt,dd {
      font-size: 14px;
      display: inline-block;
      line-height: 1.5;
      padding: 3px 0;
      // @include mq-max(sm) {
      //   padding: 5px 0;
      // }
    }
    dt {
      max-width: 265px;
      width: 100%;
      flex: 0 0 100%;
      padding: 3px 0;
      display: flex;
      align-items: center;
      @include mq-max(sm) {
        max-width: 100%;
        padding: 5px 0 0;
      }
    }
    dd {
      color: $textColor;
      font-weight: 400;
      transition: all $transition;
      @include mq-max(sm) {
        padding: 0 0 5px;
      }
    }
  }

  a {
    &[target="_blank"]{
      dd:after{
        @include fa('f35d');
        color: $keyColor;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
    &[href$=".pdf"] {
      dd:after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
  }


  &__item {
    @include mq-max(sm) {
      margin-bottom: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid $line;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &>a {
      display: flex;
      align-items: baseline;
      transition: all $transition;
      &:after {
        content: none;
      }
      &:hover {
        color: $accentColor;
        .c-newslist__date {
          color: inherit;
        }
        .c-newslist__cat {
          // color: inherit;
          background-color: $accentColor;
          &.ico_news {
            border-color: $accentColor;
            color: $white;
          }
        }
        dd {
          color: inherit;
        }
      }
    }
  }
  &__date {
    display: inline-block;
    margin-right: 15px;
    font-weight: 700;
    color: $textColor;
    transition: all $transition;
  }
  &__cat {
    font-weight: 700;
    color: $white;
    background-color: $textColorLight;
    text-align: center;
    padding: 1px 6px 3px;
    width: 100%;
    max-width: 112px;
    transition: all $transition;
    line-height: 1.2;
    &>span {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      line-height: 1;
      letter-spacing: 0.08em;
    }


    //カテゴリごとの色
    //お知らせ
    &.ico_info {
      background-color: $keyColor;
    }
    //ニュース
    &.ico_news {
      background-color: $white;
      color: $keyColor;
      border: 1px solid $keyColor;
    }
    //子育て情報
    &.ico_child {
      background-color: #e74881;
    }
    //コープでんき
    &.ico_coopdenki {
      background-color: #3ebc73;
    }
  }


  //重要なお知らせ
  &--important {
    @include mq-max(sm) {
      margin-bottom: 5px;
    }
    .c-newslist__item {
      @include mq-max(sm) {
        border: none;
        padding-bottom: 0;
      }
      &>a {
        font-size: 15px;
        &:hover {
          dt,dd {
            color: $accentColor;
          }
        }
      }
    }
    dl {
      display: flex;
      @include mq-max(sm) {
        flex-direction: column;
      }
    }
    dt,dd {
      color: $orange;
    }
    dt {
      font-weight: 700;
      margin-right: 27px;
      margin-bottom: 10px;
      @include mq-max(sm) {
        margin: 0 0 4px;
      }
    }
    // dd {}
  }

  //カテゴリ無し
  &--noCat {
    dl {
      dt {
        max-width: 150px;
      }
    }
  }

  //サムネあり
  &--thumb {
    a {
      align-items: flex-start;
    }
    .c-newslist__thumb {
      display: inline-block;
      width: 50px;
      flex: 0 0 50px;
      position: relative;
      top: 2px;
      margin-right: 10px;
      border-radius: 4px;
    }

    dl {
      flex-direction: column;
      dt {
        max-width: 100%;
      }
      dt,dd {
        padding: 2px 0;
        width: 100%;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          flex: auto;
        }
      }
    }
    .c-newslist__item {
      padding-bottom: 7px;
      margin-bottom: 8px;
      border-bottom: 1px solid $line;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}