//モーダル系SCSS

//画像ポップアップ
.modal-image {
  display: block;
  transition: all $transition;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: $box-shadow;
  }
  img {
    width: auto;
    margin: 0 auto;
    display: block;
    line-height: 1;
    @include mq-max(content) {
      width: 100%;
    }
  }
}