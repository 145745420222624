//検索
.c-searchBox {
  position: relative;
  &__input {
    background-color: $lightGray;
    border-radius: 20px;
    padding: 5px 30px 5px 12px;
    font-size: 13px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #808590;
      font-weight: 700;
    }
  }
  &__btn {
    width: 30px;
    height: 28px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    &:before {
      content: '';
      display: inline-block;
      background: url('/inc/image/common/ico/ico_search.png')no-repeat center / contain;
      width: 11.5px;
      height: 11.75px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
    }
    &>input {
      display: block;
      width: 100%;
      height: 100%;
      &:focus {
        outline: none;
      }
    }
  }
}