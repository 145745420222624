/////////////////////////////////////////////////////////////////////////
//強調
/////////////////////////////////////////////////////////////////////////
.c-bdr {
  &--yellow {
    background: linear-gradient(transparent 60%, $lightYellow 60%);
  }
}


/////////////////////////////////////////////////////////////////////////
//pタグ用
/////////////////////////////////////////////////////////////////////////
.c-txt {
  //大き目
  &--lg {
    font-size: 20px;
    font-weight: 700;
    @include mq-max(xs) {
      font-size: 16px;
    }
  }

  //補足
  &--comment {
    color: $textColorLight;
    font-size: 14px;
  }
}


/////////////////////////////////////////////////////////////////////////
//日付
/////////////////////////////////////////////////////////////////////////
.c-date {
  font-family: $Lato;
  font-weight: 700;
  color: $textColor;
  letter-spacing: .08em;
}