/////////////////////////////////////////////////////////////////////////
//上ナビ
/////////////////////////////////////////////////////////////////////////
.l-headNav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  &__head {
    display: flex;
    align-items: center;
    @include mq-max(md) {
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  &__list {
    display: flex;
    margin-left: 8px;
    transform: translateY(-5px);
    @include mq-max(md) {
      transform: none;
    }
  }
  &__item {
    &>a {
      font-size: 15px;
      font-weight: 700;
      color: $white;
      background-color: $keyColor;
      padding: 14px 18px 9px 18px;
      display: inline-block;
      transition: all $transition;
      letter-spacing: .08em;
      &:hover {
        color: $white;
        background-color: $accentColor;
        transform: translateY(5px);
        @include mq-max(md) {
          transform: none;
        }
      }
    }
    &--doc {
      &>a {
        border-radius: 0 0 6px 0;
        @include mq-max(md) {
          border-radius: 6px;
        }
        &:hover {
          border-radius: 0 0 6px 6px;
          @include mq-max(md) {
            border-radius: 6px;
          }
        }
      }
    }
    &--kentou {
      &>a {
        background-color: $conversion;
        position: relative;
        padding-left: 44px;
        border-radius: 0 0 0 6px;
        @include mq-max(md) {
          border-radius: 6px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 25.6px;
          height: 23.5px;
          background: url('/inc/image/common/ico/ico_kentou.png')no-repeat center / contain;
          position: absolute;
          top: 13px;
          left: 16px;
        }
        &:hover {
          background-color: $conversionHover;
          border-radius: 0 0 6px 6px;
          @include mq-max(md) {
            border-radius: 6px;
          }
        }
      }
    }
  }

  //よくある質問
  &__qa {
    margin-left: 10px;
    position: relative;
    top: -2px;
    @include mq-max(md) {
      margin-left: 7px;
    }
    a {
      display: inline-block;
      font-size: 15px;
      font-weight: 700;
      color: $keyColor;
      border: 1px solid $line;
      border-radius: 6px;
      padding: 2px 14px;
      background-color: $white;
      transition: all $transition;
      &:hover {
        color: $white;
        background-color: $accentColor;
        border-color: $accentColor;
      }
      @include mq-max(md) {
        text-align: center;
        padding: 6.5px 13.9px;
      }
      br {
        display: none;
        @include mq-max(md) {
          display: block;
        }
      }
    }
  }

  //パーツ調整
  .c-searchBox {
    // margin-top: 7px;
    &__input {
      width: 200px;
    }
  }

  //PC・SP表示切り替え
  &--pc {
    @include mq-max(md) {
      display: none;
    }
  }
  &--sp {
    display: none;
    @include mq-max(md) {
      display: block;
      background-color: $white;
      padding-top: 5px;
      .l-headNav {
        position: relative;
        flex-direction: column;
        padding-bottom: 15px;
        &__list {
          margin: 0;
          justify-content: center;
        }
        &__item {
          margin-right: 7px;
          &:last-child {
            margin: 0;
          }
          &>a {
            padding: 19.5px 12.5px;
          }
          &--kentou {
            &>a {
              padding-left: 52.5px;
              &:before {
                top: 20px;
              }
            }
          }
          // &--doc {}
        }

        //パーツ調整
        .c-searchBox {
          max-width: 227px;
          width: 100%;
          // margin: 0 auto 12px;
          &__btn {
            width: 40px;
            height: 42px;
          }
          &__input {
            width: 100%;
            padding: 11.5px 30px 11.5px 12px;
          }
        }
      }
    }
  }


  //新規向けTOP
  &--new {
    .l-headNav {
      &__item {
        &--member {
          &>a {
            background-color: $conversion;
            position: relative;
            padding-left: 45px;
            border-radius: 0 0 6px 6px;
            @include mq-max(md) {
              border-radius: 6px;
              padding-left: 50px;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 24.4px;
              height: 26px;
              background: url('/inc/image/common/ico/ico_member.png')no-repeat center / contain;
              position: absolute;
              top: 13px;
              left: 10px;
              @include mq-max(md) {
                top: 18px;
                left: 13px;
              }
            }
          }
        }
      }
    }
    .c-searchBox {
      display: none;
    }
  }

}


/////////////////////////////////////////////////////////////////////////
//下ナビ
/////////////////////////////////////////////////////////////////////////
.l-navigation {
//  background-color: $white;
  align-items: flex-end;
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &__inner {
    padding: 0;
    display: flex;
    background-color: $white;
    @include mq-max(md) {
      background-color: transparent;
      // background-color: $white;
      display: block;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
    }
  }
  &__item {
    margin: 0 12px;
    @include mq-max(content) {
      margin: 0 2px;
    }
    @include mq-max(md) {
      padding: 0;
      margin: 0;
      border-top: 1px solid $line;
      &:last-child{
        border-bottom: 1px solid $line;
      }
    }
    a {
      letter-spacing: .08em;
      @include mq-max(md) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 700;
      position: relative;
      @include mq-max(content) {
        padding: 10px 12px;
      }
      @include mq-max(md) {
        font-size: 16px;
      }
      &:before {
        content: '';
        width: 0%;
        height: 3px;
        background-color: $keyColor;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all $transition;
        @include mq-max(md) {
          display: none;
        }
      }
      @include mq-max(md) {
        display: block;
        padding: 15px 10px;
      }
      &:hover {
        color: $keyColor;
        &:before {
          width: 100%;
        }
      }
      &.current {
        &:before {
          width: 100%;
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include mq-max(md) {
            @include fa('f105');
            font-size: 20px;
            color: $keyColor;
            position: absolute;
            top: 50%;
            right: 21px;
            transform: translateY(-50%);
            line-height: 1;
            transition: all $transition;
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
        &[href^="javascript:void(0)"] {
          pointer-events: auto;
        }
      }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        border: 2px solid $keyColor;
        border-top: none;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        background-color: $lightGray;
        padding: 30px 74px 30px 40px;
        display: flex;
        @include mq-max(content) {
          width: calc(100% - 15px * 2);
          left: 50%;
          transform: translateX(-50%);
        }
        @include mq-max(md) {
          width: 100%;
          flex-direction: column;
          border-radius: 0;
          border: none;
          position: relative;
          transition: none;
          top: auto;
          left: 0;
          transform: none;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          padding: 0 0 0 30px;
          background-color: $lightGray;
        }

        // 閉じるボタン
        &:after {
          content: '';
          background: url('/inc/image/common/btn_close.png')no-repeat center / contain;
          width: 36px;
          height: 37px;
          display: inline-block;
          position: absolute;
          top: 12px;
          right: 14px;
          z-index: 2;
          pointer-events: auto;
          @include mq-max(md) {
            display: none;
          }
        }

        //ドロップダウンの中のリスト(1列)
        &__block {
          width: 30%;
          padding-right: 40px;
          @include mq-max(md) {
            width: 100%;
            padding: 0;
          }
          &:last-child {
            padding-right: 0;
            border-bottom: 1px none;
          }
        }
        &__list {
          &>li {
            margin-bottom: 10px;
            @include mq-max(md) {
              margin: 0;
            }
            &:last-child {
              margin-bottom: 0;
              @include mq-max(md) {
                border-bottom: none;
              }
            }
            &>a {
              display: block;
              font-size: 15px;
              font-weight: 700;
              color: $textColor;
              position: relative;
              padding: 5px 15px 5px 0;
              transition: all $transition;
              @include mq-max(md) {
                font-size: 16px;
                height: 0;
                padding: 0;
                transition: height $transition,
                            padding $transition;
              }
              &:before {
                content: '';
                background-color: $lightBlue1;
                width: 0%;
                height: calc(100% + 5px * 2);
                display: block;
                border-radius: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: -1;
                opacity: 0;
                transition: all $transition;
                @include mq-max(md) {
                  display: none;
                }
              }
              &:after {
                @include fa('f105');
                transition: all $transition;
                font-size: 20px;
                color: $keyColor;
                position: absolute;
                top: .35em;
                right: 0;
                line-height: 1;
                @include mq-max(md) {
                  right: 21px;
                  top: .8em;
                }
              }
              &:hover {
                color: $accentColor;
                &:before {
                  opacity: 1;
                  width: calc(100% + 18px * 2);
                }
                &:after {
                  color: $accentColor;
                  right: -5px;
                  @include mq-max(md) {
                    right: 21px;
                  }
                }
              }
            }
          }
        }
      }

      //クリックして開いた時
      &.is-opened {
        &>a {
          background-color: $lightGray;
          border-radius: 6px 6px 0 0;
          @include mq-max(md) {
            border-bottom: 1px solid $line;
            background-color: $white;
            border-radius: 0;
          }
          &:before {
            width: 100%;
          }
          &:after {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
            opacity: 1;
            display: block;
          }
          &__block {
            @include mq-max(md) {
              border-bottom: 1px solid $line;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          &__list {
            &>li {
              @include mq-max(md) {
                border-bottom: 1px solid $line;
                &:last-child {
                  border-bottom: none;
                }
              }
              &>a {
                @include mq-max(md) {
                  height: auto;
                  padding: 13px 50px 13px 15px;
                }
              }
            }
          }
        }
      }
    }
  }


  //新規向けTOP
  &--new {
    .l-navigation {
      &__item {
        margin: 0;
        @include mq-max(md) {
          margin: 0;
        }
        &>a {
          padding: 10px 8px;
          @include mq-max(content) {
            padding: 10px 6px;
            font-size: 13px;
          }
          @include mq-max(md) {
            padding: 15px 10px;
            font-size: 16px;
          }
          &:before {
            background-color: $keyColor-new;
          }
          &:hover {
            color: $keyColor-new;
          }
        }
      }
    }
  }

}
