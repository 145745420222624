//アコーディオン

.c-accordion {
  box-shadow: $box-shadow;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: $white;
  border: 3px solid transparent;
  transition: all $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $lightBlue2;
    border-color: $accentColor;
    .c-accordion__title {
      color: $accentColor;
    }
  }
  &.open {
    .c-accordion__title {
      &:after {
        transform: rotate(-180deg) translateY(14px);
        color: $accentColor;
      }
    }
  }

  &__title {
    padding: 15.5px 53px 15.5px 20px;
    font-size: 16px;
    font-weight: 700;
    color: $keyColor;
    position: relative;
    cursor: pointer;
    transition: all $transition;
    &:after {
      @include fa('f107');
      font-size: 16px;
      color: $keyColor;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 23px;
      transform: translateY(-50%);
      transition: all $transition;
    }
  }
  &__body {
    font-size: 16px;
    padding: 0 53px 20px 20px;
    line-height: calc(28 / 16);
    display: none;
    @include mq-max(xs) {
      padding-right: 30px;
    }
  }

  //Q&A
  // @mixin faqIcon($string) {
  //   content: $string;
  //   display: inline-block;
  //   border-radius: 50%;
  //   font-size: 22px;
  //   line-height: 1;
  //   text-align: center;
  //   position: absolute;
  //   top: 15px;
  //   left: 20px;
  // }
  &.c-accordion--faq {
    .c-accordion__title {
      padding-left: 45px;
      position: relative;
      &:before {
        content: '';
        width: 18px;
        height: 20px;
        background: url('/inc/image/common/ico/ico_question.svg')no-repeat center / contain;
        display: inline-block;
        position: absolute;
        line-height: 1;
        top: 18px;
        left: 20px;
        color: $keyColor;
        backface-visibility: hidden;
      }
      &:hover {
        &:before {
          // color: $accentColor;
          background-image: url('/inc/image/common/ico/ico_question-hover.svg');
        }
      }
    }
    .c-accordion__body {
      padding-left: 20px;
      position: relative;
    }
  }
}