//テーブル
.c-tbl {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th,td {
    border: 1px solid $line;
    padding: 15.5px 24px;
    font-size: 16px;
    font-weight: 400;
  }
  thead {
    th {
      text-align: left;
      font-weight: 700;
      &.blank {
        padding: 0;
        background-color: $lightBlue2;
      }
      &:not(.blank) {
        width: 38%;
        background-color: $lightGray;
      }
    }
  }
  tbody {
    th {
      width: 192px;
      font-weight: 700;
      background-color: $lightBlue2;
      text-align: left;
    }
    td {
      &.is-price {
        text-align: right;
      }
    }
  }

  //width auto
  &--autoWidth {
    th {
      width: auto !important;
    }
    &-xs {
      th {
        @include mq-max(xs) {
          width: auto !important;
        }
      }
    }
  }

  //全部中央寄せ
  &--center {
    thead,tbody {
      th,td {
        text-align: center;
      }
    }
  }

  //デフォルト
  &--block {
    tbody{
      tr{
        @include mq-max(xs){
          &:not(:last-child){
            td{
              border-bottom: none;
            }
          }
        }
        th{
          @include mq-max(xs){
            border-bottom: none !important;
          }
        }
        td{
          width: calc(100% - 192px);
        }
        th,td{
          @include mq-max(xs){
            display: block;
            width: 100%;
          }
        }
      }
    }
  }


  //商品比較型
  &--compare {
    @include mq-max(xs) {
      width: 100%;
    }
    thead {
      @include mq-max(xs) {
        display: none;
      }
    }
    tbody {
      tr,td {
        @include mq-max(xs) {
          display: block;
          width: 100%;
          position: relative;
        }
      }
      tr {
        @include mq-max(xs) {
          margin-bottom: 25px;
        }
        &:last-child {
          @include mq-max(xs) {
            margin-bottom: 0;
          }
        }
      }
      td {
        vertical-align: middle;
        font-size: 16px;
        &:last-child {
          @include mq-max(xs) {
            border-bottom: 1px solid $bdrColor;
          }
        }
        @include mq-max(xs) {
          display: block;
          padding: 0;
          font-size: 14px;
          border-bottom: none;
        }
        &:before {
          @include mq-max(xs) {
            content: attr(data-tblName);
            display: block;
            text-align: left;
            padding: 17px 24px;
            border-bottom: 1px solid $bdrColor;
            background-color: #F8F8F8;
          }
        }
        span {
          @include mq-max(xs) {
            display: block;
            padding: 17px 24px;
          }
        }
      }
      th {
        @include mq-max(xs) {
          width: 100%;
          display: block;
          background-color: $keyColor;
          color: $white;
          border-bottom: none;
        }
      }
    }
  }


  //スクロール型
  &__scroll {
    @include mq-max(xs) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #cecece;
      border-radius: 5px;
      &:before {
        content: "←スライドで表の詳細を確認できます→";
        color: $textColorLight;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: 800px;
        th, td {
          display: table-cell;
        }
        th {
          white-space: nowrap;
        }
      }
    }
  }

}
