//ページトップ
.pageTop{
  position: fixed;
  z-index: 1000;
  bottom: 40px;
  right: 30px;
  width: 47px;
  height: 47px;
  display: block;
  background-color: rgba($keyColor,.3);
  border-radius: 50%;
  border: 1px solid rgba($white,.3);
  line-height: 1;
  transition: all $transition;
  @include mq-max(md) {
    width: 40px;
    height: 40px;
    bottom: 18px;
    right: 13px;
    background-color: $accentColor;
  }
  &:after {
    @include fa('f077');
    display: inline-block;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all $transition;
  }
  &:hover {
    width: 61px;
    height: 61px;
    bottom: 33px;
    right: 23px;
    background-color: rgba($accentColor,.3);
    @include mq-max(md) {
      width: 40px;
      height: 40px;
      bottom: 18px;
      right: 13px;
      background-color: $accentColor;
    }
    &:after {
      top: 44%;
      @include mq-max(md) {
        top: 50%;
      }
    }
  }
}
