//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$contentWidth    : 1170px;// 全体のコンテンツ幅
$contentWidth-lower : 800px;// 下層コンテンツ幅
$contentPadding  : 30px;//パディング:gridlexでも使用
$tablet-break    : 768px;//タブレットでのブレイクポイント
$mobile-break    : 568px;//モバイルでのブレイクポイント
$padding-break   : 40px;//基本のコンテンツ余白
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定

//----------------------------------------------------------------
//    header fixed設定
//----------------------------------------------------------------
$is-fixed        : true;
$headerHeight    : 102px;
$headerHeight-sp : 71.2px; //ここを変えたら、menu.jsの「headerHeight」の値も変えること！


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width(gridlexに合わせる)
$breakpoint-max: (
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'bp700': 'screen and (max-width: 700px)', //スマホ境界
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'md': 'screen and (max-width: 1024px)',
  'content': 'screen and (max-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (max-width: 1280px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'md': 'screen and (min-width: 1024px)',
  'content': 'screen and (min-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (min-width: 1280px)',
  'bp1600': 'screen and (min-width: 1600px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white           : #FFFFFF;
$gray            : #CCCCCC;
$black           : #000000;
$gray-l          : lighten( $gray, 15%);
$red             : #c82e2e;

$keyColor        : #4363a5; //キーカラー
$keyColor-new    : #5EA76C;
$keyColor-new-hover : #EDFEFF;
$keyColor-new-light : #ECFCF1;
$accentColor     : #5ea1e5; //アクセントカラー
$accentColor-new : #169B96; //アクセントカラー
$conversion      :#f29174;
$conversionHover : #f26e6e;

$lightYellow     : #fffbe0; //XDではbgBox
$lightBlue1   :#e1edf7; //XDではbgTop
$lightBlue2       : #edf4fa; //XDではbgTable
$lightGray       : #f2f2f2; //XDではbg
$lightRed        : #E86166;
$orange          : #ED6840;

$top-yellow: #FCF9BB; //TOPのみで使用

$line            : #d1d1d1;
$line-menu       : #eaeaea; //menuの縦線
$bdrColor        : $gray;

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$FontAwesome: 'Font Awesome 5 Free';
$Lato       : 'Lato', sans-serif;

$textColor: #3b404b; //XDではtext
$textColorLight: #808590; //XDではtextWeak
$textColorDark: darken($textColor,10);
$lineHeight: 1.5; // 基本的な行間

$box-shadow : 0px 0px 4px rgba(0, 0, 0, 0.16);

// サンセリフ・ゴシック体
$sanserif: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//----------------------------
//    コンテンツ
//---------------------------
$content-bg       : $white;
$font-size        : 190%;//全体のフォントサイズ
$full-size   : 100%;//基本のコンテンツ余白

//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 3px;//全体の角丸サイズ
$transition: .2s ease;

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------
$btn-width       : 350px;//PCサイトアクションボタンの大きさ
$border-btn          : none;//テーブルのボーダーカラー
$border-btn-width   : 0px;//テーブルのボーダーサイズ
$border-txt-color   : $white;//テーブルのボーダーサイズ
